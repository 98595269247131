import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import handleDocumentClick from '../../common/handleDocumentClick';
import { Locale } from '../../frontend/types';
export type Option = {
  value: string;
  label: string;
  showChecked?: boolean;
  showSoldout?: boolean;
};

/** React port of the jQuery styled front-end select boxes */
const StyledSelect: React.FC<{
  id?: string;
  name: string;
  value?: string;
  disabled?: boolean;
  locale: Locale;
  onChange: (value: any) => void;

  /* add a glow indicating that this control should get attention */
  highlight?: boolean;
  options: Option[];
}> = (props) => {
  const container = useRef(null);
  const [open, setOpen] = useState(false);
  const selectedValue = props.value || '';
  const selectedOption = props.options.find((option) => option.value === selectedValue);
  const selectedLabel: string = selectedOption?.label || '';

  useEffect(() => {
    return handleDocumentClick(open, container, setOpen);
  }, [open]);

  useEffect(() => {
    setOpen(false);
  }, [props.value]);

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    if (props.disabled) return;
    const newValue = event.currentTarget.dataset.value || '';

    // same value so bail early and manually close the dropdown
    if (newValue == selectedValue) {
      setOpen(false);
      return;
    }
    props.onChange(newValue);
  };

  const onTriggerClick = (event: React.MouseEvent<HTMLElement>) => {
    if (props.disabled) return;
    event.preventDefault();
    setOpen(!open);
  };

  const optionItems = props.options.map(({ value, label, showChecked, showSoldout }) => {
    const selected = value === selectedValue;
    const klass = classNames({ selected: selected, 'styled-select-item': true });
    const check = showChecked ? (
      <span className="checked-indicator">
        <i className="ti ti-checkmark"></i>
      </span>
    ) : null;

    let soldout = null;
    if (showSoldout && !showChecked) {
      const str =
        props.locale == 'fr-ca' ? (
          'épuisé'
        ) : (
          <span>
            sold
            <br />
            out
          </span>
        );
      soldout = <span className="soldout-indicator">{str}</span>;
    }

    return (
      <li key={value} data-value={value} onClick={onClick} className={klass}>
        <span className="label">{label}</span>
        {check}
        {soldout}
      </li>
    );
  });

  function renderDropdown() {
    if (!open) return null;
    return (
      <ul className="select-options" style={{ display: open ? 'block' : 'none' }}>
        {optionItems}
      </ul>
    );
  }

  const classes = classNames({
    'select-styled': true,
    active: open,
    'select-styled-disabled': props.disabled,
    'select-styled-highlight': props.highlight,
    'select-styled-enabled': !props.disabled,
  });

  return (
    <div className="select" id={props.id} ref={container}>
      <div className={classes} onClick={onTriggerClick}>
        {selectedLabel}
      </div>
      {renderDropdown()}
    </div>
  );
};

export default StyledSelect;
