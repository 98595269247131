import { dispatchOpenMultipackBuilderEvent } from "./multipackBuilder";

export const ACTIVE_CLASS = 'active';
export const HIDDEN_CLASS = 'select-hidden';
export const DISPLAY_CLASS = 'select-styled';
export const OPTIONS_CLASS = 'select-options';
export const UPDATE_DISPLAY_EVENT = 'select-update-display';

// modified select box UI formatting heavily based on https://codepen.io/wallaceerick/pen/ctsCz
function initSelect() {
  const selectNode: JQuery<HTMLSelectElement> = $(this);
  if (selectNode.parent().hasClass('select')) return;
  if (selectNode.hasClass('no-select-style')) return;
  const numberOfOptions = $(this).children('option').length;

  selectNode.addClass(HIDDEN_CLASS);
  selectNode.wrap('<div class="select"></div>');
  selectNode.after(`<div class="${DISPLAY_CLASS}"></div>`);

  const styledSelect = selectNode.next(`div.${DISPLAY_CLASS}`);
  const initialValue = selectNode.val();
  const initialValueNode =
    selectNode.find(`option[value="${initialValue}"]`) || selectNode.children('option').eq(0);

  styledSelect.text(initialValueNode.text());

  const list = $('<ul />', {
    class: OPTIONS_CLASS,
  }).insertAfter(styledSelect);

  for (let i = 0; i < numberOfOptions; i++) {
    $('<li />', {
      text: selectNode.children('option').eq(i).text(),
      rel: selectNode.children('option').eq(i).val(),
    }).appendTo(list);
  }

  function updateDisplay(event) {
    if (!event) return;

    const newSelectedText = selectNode.find(`option[value="${event.target.value}"]`).text();
    if (newSelectedText) {
      styledSelect.text(newSelectedText);
    }
  }

  selectNode.change(updateDisplay);

  // alternate event handler to update the displayed value indepedent of firing the main 'change'
  // event (and potentially triggering other unwanted callbacks)
  selectNode.on(UPDATE_DISPLAY_EVENT, updateDisplay);

  styledSelect.on('click', function (e) {
    e.stopPropagation();
    $(`div.${DISPLAY_CLASS}.${ACTIVE_CLASS}`)
      .not(this)
      .each(function () {
        $(this).removeClass(ACTIVE_CLASS).next(`ul.${OPTIONS_CLASS}`).hide();
      });
    $(this).toggleClass(ACTIVE_CLASS).next(`ul.${OPTIONS_CLASS}`).toggle();
  });

  list.children('li').click(function (e) {
    e.stopPropagation();

    const listItem: JQuery<HTMLLIElement> = $(this);
    const quantity = parseInt(listItem.attr('rel'), 10);

    if (listItem.closest('.select-tickets-row-multipack').length && quantity > 0) {
      dispatchOpenMultipackBuilderEvent(listItem[0], quantity);
      return;
    }

    const selectedValue = listItem.attr('rel');
    styledSelect.text(listItem.text()).removeClass(ACTIVE_CLASS);
    selectNode.val(selectedValue);
    selectNode.find('option').removeAttr('selected');
    selectNode.find(`option[value="${selectedValue}"]`).attr('selected', 'selected');
    selectNode.trigger('change');
    list.hide();
  });

  $(document).click(function () {
    styledSelect.removeClass(ACTIVE_CLASS);
    list.hide();
  });
}

export default function () {
  // should match the selector in _select_boxes.scss
  const selector = 'select.quantity-input, .form-wrapper select';
  $(document).on('tickit.update-ui', () => {
    $(selector).each(initSelect);
  });

  $(selector).each(initSelect);
}
