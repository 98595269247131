import React, { useEffect, useRef, useState } from 'react';
import Item from './Item';
import StyledSelect, { Option } from './StyledSelect';
import { fetchData, postData } from './transportData';
import { ICart, LoadingState, Locale } from '../../frontend/types';
import { IDates, IItem, ITimeslots } from './types';
import NoTicketsNotice, { SelectionState } from './NoTicketsNotice';
import { CART_UPDATED_EVENT_TYPE } from '../../frontend/cart';
import { needToShowPreAddWarning, confirmPreAddWarning } from '../../frontend/preAddWarning';
export interface IProps {
  endpoint: string;
  event_id: string;
  title: string;
  dates?: IDates;
  items: IItem[];
  timeslots?: ITimeslots;
  cart?: ICart;
  entire_day: boolean;
  locale: Locale;
}

function determineSelectionState(
  entire_day: boolean,
  selectedDate: string | undefined,
  selectedOccurrenceID: string | undefined
): SelectionState {
  // no date
  if (!selectedDate) return SelectionState.MissingDate;

  // date has been selected and we're in entire_day mode
  if (entire_day) return SelectionState.Ready;

  // multiple timeslots
  // with timeslot selected
  if (selectedOccurrenceID) return SelectionState.Ready;
  return SelectionState.MissingTimeslot;
}

const AddOccurrencesToCart: React.FC<IProps> = (props) => {
  const didMount = useRef(false);
  const [loading, setLoading] = useState<LoadingState>(LoadingState.Loading);
  const [dates, setDates] = useState<IDates>(props.dates || {});
  const [items, setItems] = useState<IItem[]>(props.items || []);
  const [timeslots, setTimeslots] = useState<ITimeslots>(props.timeslots || {});
  const [cart, setCart] = useState<ICart>(props.cart || undefined);

  // we default to no date selected
  const [date, setDate] = useState<string>(undefined);

  const [selectedOccurrenceID, setSelectedOccurrenceID] = useState<string>(undefined);

  const selectionState = determineSelectionState(props.entire_day, date, selectedOccurrenceID);

  const mergeEndpoint = (endpoint, date, selectedOccurrenceID) => {
    const url = new URL(endpoint);
    const params = new URLSearchParams({});
    if (date) {
      params.set('date', date);
    }

    if (props.entire_day === false) {
      params.set('event_occurrence_id', selectedOccurrenceID || '');
    }

    url.search = params.toString();
    return url.toString();
  };

  const mergedEndpoint = mergeEndpoint(props.endpoint, date, selectedOccurrenceID);

  useEffect(() => {
    // TODO: this is doing double fetches for events with TIMESLOTS, the first with timeslot=nil
    // and then again with the default timeslot
    setLoading(LoadingState.Loading);
    fetchData(mergedEndpoint).then((data) => {
      setTimeslots(data.timeslots);
      setSelectedOccurrenceID(
        data.selected_event_occurrence_id ? data.selected_event_occurrence_id.toString() : undefined
      );
      setDates(data.dates);
      setItems(data.items);

      // only dispatch cart update events when the items have changed -- we don't compare the ENTIRE cart since the time
      // remaining is always changing
      const oldCart = JSON.stringify(cart?.cartItems);
      const newCart = JSON.stringify(data.cart?.cartItems);

      if (oldCart != newCart) {
        setCart(data.cart);
      }

      setLoading(LoadingState.Ready);
    });
  }, [mergedEndpoint, cart, date, selectedOccurrenceID]);

  const busy = loading == LoadingState.Loading || loading == LoadingState.Posting;

  const onUpdateCart = (itemID: string, quantity: number) => {
    setLoading(LoadingState.Posting);
    if (needToShowPreAddWarning()) {
      if (!confirmPreAddWarning()) {
        setLoading(LoadingState.Posting);
        return false;
      }
    }
    postData(mergedEndpoint, itemID, quantity).then((data) => {
      setCart(data.cart);
      setLoading(LoadingState.Ready);
    });
  };

  const onChangeDate = (date: string) => {
    setDate(date);
    setSelectedOccurrenceID(undefined);
  };

  useEffect(() => {
    // don't fire on initial render, https://stackoverflow.com/a/53180013
    if (cart) {
      if (didMount.current) {
        // console.debug(`dispatch ${CART_UPDATED_EVENT_TYPE}`);
        const event = new CustomEvent<ICart>(CART_UPDATED_EVENT_TYPE, { detail: cart });
        document.dispatchEvent(event);
      } else {
        didMount.current = true;
      }
    }
  }, [cart]);

  const renderItems = () => {
    if (items.length < 1) {
      return <NoTicketsNotice selectionState={selectionState} />;
    }

    const components = items.map((item) => {
      const cartItem = cart?.cartItems[item.id];
      const quantity = cartItem?.q || 0;

      return (
        <Item
          locale={props.locale}
          item={item}
          key={item.id}
          onUpdateCart={onUpdateCart}
          busy={busy}
          quantity={quantity}
        />
      );
    });

    return (
      <div className="expandable select-tickets-group">
        <div className="select-tickets-group-body">{components}</div>
      </div>
    );
  };

  const dateInCart = (date: string): boolean => {
    if (!cart || cart.totalItems < 1 || !cart.cartItems) return false;

    const cartDates = Object.values(cart.cartItems).map((cartItem) => cartItem.occ_d);
    const occ_d = `e${props.event_id}-${date}`;
    return cartDates.includes(occ_d);
  };

  const timeslotInCart = (timeslotID: string): boolean => {
    if (!cart || cart.totalItems < 1 || !cart.cartItems) return false;

    const cartItemIDs = Object.values(cart.cartItems).map((cartItem) => cartItem.occ_id);
    return cartItemIDs.includes(timeslotID);
  };

  const renderDates = () => {
    const options: Option[] = Object.keys(dates || {})
      .sort()
      .map((value) => {
        const labelBase = dates[value];
        const showChecked = dateInCart(value);
        const label = `${labelBase}`;
        return { label, value, showChecked };
      });

    options.unshift({ value: '', label: window.Tickit_Checkout_i18n.select_date });

    const highlight = !busy && !date && options.length > 0;

    return (
      <div className="occurrence-timeslot-selectors-select occurrence-timeslot-selectors-date">
        <StyledSelect
          name="date"
          highlight={highlight}
          disabled={busy}
          value={date}
          onChange={onChangeDate}
          options={options}
          locale={props.locale}
        />
      </div>
    );
  };

  const renderTimeslots = () => {
    if (props.entire_day) return false;
    const timeslotIDs = Object.keys(timeslots);

    const options: Option[] = timeslotIDs
      .map((id) => {
        const timeslot = timeslots[id];
        const value = id;
        return {
          value,
          label: `${timeslot.label}`,
          starts_at: timeslot.starts_at,
          showChecked: timeslotInCart(id),
          showSoldout: timeslot.soldout,
        };
      })
      .sort((a, b) => {
        return a.starts_at - b.starts_at;
      });

    if (options.length > 1) {
      options.unshift({ value: '', label: window.Tickit_Checkout_i18n.select_time });
    }

    const disabled = busy || options.length <= 1;
    const highlight = !busy && date && !selectedOccurrenceID && options.length > 0;
    return (
      <div className="occurrence-timeslot-selectors-select occurrence-timeslot-selectors-time">
        <StyledSelect
          name="timeslot"
          disabled={disabled}
          highlight={highlight}
          value={selectedOccurrenceID}
          onChange={setSelectedOccurrenceID}
          options={options}
          locale={props.locale}
        />
      </div>
    );
  };

  return (
    <div className="select-tickets-group">
      <div className="select-tickets-row select-tickets-group-header select-tickets-occurrence-header">
        <div className="group-header">
          <div className="group-title">{props.title}</div>
        </div>
        <div className="occurrence-timeslot-selectors">
          {renderDates()}
          {renderTimeslots()}
        </div>
      </div>
      {renderItems()}
    </div>
  );
};

export default AddOccurrencesToCart;
